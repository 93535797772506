@import '../../styles/_colors';

.report-side-nav {
  font-size: 0.8em;

  .nav-header {
    font-size: 1em;
    font-weight: 600;
    color: $mm-grey-text;
    margin: 1em 0 0.8em;
    padding: 1em 1em 0;
    border-top: 1px solid $mm-stone;
  }

  .nav-item {
    background: darken($mm-light-stone, 3%);
    margin-bottom: .3em;
    border-radius: 3px;

    .nav-link {
      &:not([disabled]) {
        color: $mm-blue;

        &.active,
        &:hover {
          background: $mm-turquoise;
          color: $mm-white;
        }
      }
    }

    &.disabled {
      opacity: 0.8;

      a {
        cursor: not-allowed;
      }
    }
  }

  .sub-nav {
    padding-left: 1.2em;
  }
}