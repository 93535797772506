@import '../../styles/_colors';

.how-to-read-page {
  .page-section {
    padding-top: 1em;
    padding-bottom: 1em;

    &:first-child {
      border-top: 0;
    }

    .section-title {
      margin-bottom: 1em;
    }
  }
}