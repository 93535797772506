.page-header {
  .logos {
    display: flex;
    height: 120px;
    padding: 20px 0;

    img {
      height: 80px;
      width: auto;
    }

    a {
      flex: 0 0 auto;
      margin-right: 15px;

      &:nth-child(2) {
        margin-left: auto;
      }
    }
  }
}