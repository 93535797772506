@import '../../styles/colors';

.response-rate-bar {
  display: flex;
  flex-direction: row;

  .progress {
    flex: 1 1 auto;

    .progress-bar {
      white-space: nowrap;
      color: $mm-light-stone;

      &.bg-warning {
        color: $mm-grey-text;
      }
    }
  }

  .percentage-display {
    font-size: 14px;
    line-height: 16px;
    padding: 0 0.5em;
    text-align: right;
    width: 50px;
    white-space: nowrap;
  }
}