@import '../../styles/colors';

.organizations-list-container {
  padding: 0 0 50px;

  table {
    thead {
      .name-col {
        width: 60%;
      }

      th.report-link-col {
        min-width: 135px;
      }

      th.response-rates-col {
        min-width: 300px;
      }
    }

    tbody {
      tr.level-1 {
        th {
          padding-left: 1.5rem;
        }
      }

      tr.level-2 {
        font-size: 16px;

        th {
          padding-left: 2.5rem;
        }
      }

      th.expandable {
        cursor: pointer;

        &:hover,
        > svg {
          color: $mm-blue;
        }
      }

      td.ineligible {
        color: $mm-dark-stone;
        cursor: help;
      }

      td .progress {
        height: 24px;
      }

      td .percentage-display {
        line-height: 24px;
      }
    }
  }
}