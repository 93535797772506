@import '../../styles/_colors';

.pdf-download-page {
  .report-link-container {
    margin: 2em 0;

    h5 {
      font-size: 1em;
      font-weight: 400;

      svg {
        font-size: 2em;
        margin-right: 0.4em;
        vertical-align: middle;
      }
    }
  }

  .loading-box {
    text-align: center;
    padding: 100px 0;

    .loader-active {
      display: inline-block;
    }
  }

  .error-reason {
    color: $mm-red;
    opacity: 0.4;
  }
}
