.module-overview-page {
  .module-score-container {
    text-align: center;

    span {
      font-size: 0.7em;
      text-transform: uppercase;
    }

    .score-arc-svg {
      width: 180px;
      height: 90px;
      margin: 0.2em 0;
    }
  }

  .page-intro {
    padding-right: 1em;

    .intro-right {
      flex: 0 0 180px;
    }
  }

  .construct-card {
    margin-bottom: 2em;
  }
}
