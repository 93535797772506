@import '../../styles/colors';

.organization-insights-table {
  thead {
    .construct-icons {
      th {
        height: 30px;
        text-align: center;
        border-top: 0;
        border-bottom: 0;
        padding: 0;
      }

      svg {
        width: 30px;
        height: 30px;
        display: inline-block;
      }
    }

    .subject-scores {
      th {
        border-top: 0;
        border-bottom: 0;
        padding: 5px;
        }
      }

    th {
      &:first-child {
        min-width: 120px;
        text-align: right;
        padding-right: 10px;
      }

      &:last-child,
      &:first-child {
        vertical-align: middle;
        text-transform: uppercase;
        font-size: 0.7em;
        color: $mm-grey-text;
      }

      .score-box {
        width: 60px;
        height: 60px;
      }
    }
  }

  tbody {
    th {
      font-size: 0.8em;
      line-height: 1.1em;
    }

    th,
    td {
      padding: 0.5rem 5px;
      vertical-align: middle;
      border: 0;
    }

    tr:hover {
      background-color: lighten($mm-light-stone, 2%);
    }
  }
}
