@import '../../styles/_colors';

.demogrpahic-item-response-distribution {
  display: table;
  width: 100%;

  .total-responses {
    padding: 0 1em 0 0;
    text-align: center;
    font-size: 0.9em;
  }

  .donut-chart,
  .response-anchors-container {
    display: table-cell;
    vertical-align: middle;
    width: 40%;
  }

  .donut-chart {
    width: 30%;

    svg {
      max-width: 280px;
      .segment {
        circle {
          transition: all 0.2s;
        }
      }
    }
  }

  .response-anchors-container {
    padding-left: 1em;

    ul {
      margin: 0;
      padding: 0;
      display: inline-block;
      list-style: none;
    }

    li {
      .legend-block {
        display: inline-block;
        width: 0.8em;
        height: 0.8em;
        margin-right: 0.3em;
      }

      &.highlighted {
        background-color: $mm-light-stone;
      }
    }
  }
}