@import '../../styles/colors';

.hero-unit {
  background-image: url(../../images/bg_feature_2_mini.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 65%;
  background-size: cover;
  color: rgba($mm-white, 0.95);
  position: relative;
  margin-bottom: 30px;

  h1 {
    font-size: 32px;
    font-weight: 300;
    text-shadow: 0 0 2px $mm-dark-stone;
    margin-bottom: 2em;
  }

  .intro-content-container {
    padding: 100px 0 80px;
  }

  .content {
    width: 100%;
  }

  .round-down-arrow {
    position: absolute;
    background: $mm-dark-blue;
    padding: 2px;
    height: 50px;
    width: 50px;
    text-align: center;
    border-radius: 25px;
    left: 50%;
    bottom: -25px;
    margin-left: -25px;
  }

  .summary-report-link {
    margin-top: 1em;
  }
}

@media screen and (min-width: 1024px) {
  .hero-unit {
    .intro-content-container {
      display: flex;
      flex-wrap: nowrap;
    }

    .content {
      flex: 0 0 60%;
    }

    .action-buttons {
      flex: 0 0 40%;
      text-align: center;
    }

    .report-cover-image {
      width: 180px;
      height: auto;
    }

    .report-cover-link {
      display: inline-block;
      box-shadow: 2px 1px 1px rgba(0,0,0,0.15);
      background-color: #fff;
      position: relative;
      z-index: 0;
      transition: all 0.2s;

      &::before,
      &::after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #eee;
        box-shadow: 2px 1px 1px rgba(0,0,0,0.15);
        transition: all 0.2s;
      }

      &::before {
        left: 4px;
        top: 2px;
        z-index: -1;
      }

      &::after {
        left: 7px;
        top: 5px;
        z-index: -2;
      }

      &:hover {
        transform: translate(3px, 3px);

        &::before {
          transform: translate(3px, 3px);
        }

        &::after {
          transform: translate(5px, 5px);
        }
      }
    }

    .summary-report-link {
      margin: 2em auto 0;
    }
  }
}
