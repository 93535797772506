@import '../../styles/_colors';

.result-comparison {

  .comparison-row {
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-wrap: nowrap;
    margin: 2px 0;
    padding: 0 4px;

    .progress-bar {
      white-space: nowrap;
      overflow: visible;
      text-indent: 1em;
      color: $mm-dark-stone;
    }

    .title,
    .comparison-bar {
      flex: 1;
    }

    .title {
      max-width: 100px;
      font-size: 14px;
      line-height: 1em;
      padding-right: 0.5em;
    }

    .result-display {
      flex: 0 0 55px;
      padding: 0 0 0 9px;
    }

    .score-display {
      display: inline-block;
      position: relative;
      height: 30px;
      padding: 0 6px;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
      border-radius: 3px;
      transition: all 0.2s ease;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        height: 0;
        width: 0;
        top: 8px;
        left: -6px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;

        border-right: 6px solid transparent;
        transition: all 0.2s ease;
      }
    }

    &.highlighted {
      .title {
        font-weight: bold;
      }

      .score-display {
        border: 1px solid darken($mm-dark-blue, 3%);
        border-left: 0;
        background: $mm-dark-blue;
        color: $mm-white;

        &:after {
          border-right: 6px solid $mm-dark-blue;
        }
      }
    }
  }
}