@import '../../styles/_colors';

.result-disaggregation {
  width: 100%;
  color: $mm-grey-text;

  .header {
    border-bottom: 1px solid $mm-stone;
    padding: 0.5em;

    .title,
    .result-bar {
      color: darken($mm-grey-text, 10%);
      font-weight: bold;
      text-align: center;
    }

    .title {
      .dropdown-toggle {
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .header,
  .disaggregation-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch; 

    .title {
      flex: 0 0 170px;
    }

    .result-bar {
      flex: 1;
    }
  }

  .disaggregation-row {
    min-height: 40px;
    height: 39px; // hack to fix IE problems

    .title {
      display: flex;
      align-items: center;
      padding-right: 1em;
      font-size: 14px;
      line-height: 1em;

      word-break: break-word;
      hyphens: auto;

      p {
        margin: 0;
        text-align: right;
        width: 100%;
      }
    }

    .result-bar {
      position: relative;
      margin-right: 55px;
      display: flex;
      justify-content: left;
      align-items: center; 
      border-left: 1px solid $mm-stone;

      .bar {
        display: inline-block;
        height: 1.2em;
        background: $mm-turquoise;
        border-radius: 0 3px 3px 0;
      }

      &.threshold-not-met {
        padding-left: 1em;
        font-size: 0.8em;
        color: $mm-dark-stone;
      }
    }

    .score-container {
      flex: none;
      margin-left: 0.8em;
      position: absolute;

      display: inline-block;
      height: 30px;
      padding: 0 6px;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
      border-radius: 3px;
      transition: all 0.2s ease;
      border-left: 0;

      background: none;
      color: $mm-grey-text;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        height: 0;
        width: 0;
        top: 8px;
        left: -6px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;

        border-right: 6px solid transparent;
        transition: all 0.2s ease;
      }
    }

    &.highlighted {
      font-weight: 600;  

      .score-container {
        background: $mm-turquoise;
        color: $mm-white;

        &:after {
          border-right: 6px solid $mm-turquoise;
        }
      }

    }
  }
}