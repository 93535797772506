@import '../../styles/_colors';

.survey-item {
  width: 100%;
  background: $mm-light-stone;

  .survey-item-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 1em;
  }

  .q-mark {
    flex: 0 0 30px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    width: 30px;
  }

  .item-text {
    flex: 1;
    padding: 1em;
  }

  .score-box {
    align-self: stretch;
    width: 64px;
  }
}