@import '../../styles/colors';

.report-layout {
  .loading-box {
    padding: 100px 0;

    .loader {
      display: inline-block;
    }

    text-align: center;

    p {
      color: $mm-grey-text;
      clear: both;
    }
  }

  .report-dropdown-nav {
    margin-top: 2.5em;

    .dropdown-toggle {
      min-width: 300px;
    }
  }

  .report-body-container {
    padding-top: 2.5em;

    display: flex;
    flex-wrap: nowrap;

    .report-content {
      flex: 1;
      background: $mm-white;
      border-radius: 3px;
      box-shadow: 1px rgba(0,0,0,0.3);
      position: relative;

      &:after {
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        left: 80px;
        top: -24px;
        border: 12px solid transparent;
        border-bottom-color: $mm-white;
      }
    }

    .report-nav {
      flex: 0 0 220px;
      margin-left: 3em;
    }
  }

  .report-page {
    &.fade-enter {
      opacity: 0.01;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 20;
    }

    &.fade-exit {
      opacity: 1;
      position: relative;
      z-index: 10;
    }

    &.fade-enter-active {
      opacity: 1;
      transition: opacity 200ms ease-in;
    }

    &.fade-exit-active {
      opacity: 0.01;
      transition: opacity 200ms ease-in;
    }
  }
}