@import '../../styles/_colors';

.tabbed-details {

  .nav-tabs {
    border-bottom-color: $mm-stone;

    .nav-link {
      font-size: 0.8em;
      cursor: pointer;
      color: $mm-turquoise;

      &.active {
        cursor: default;
        background-color: $mm-light-stone;
        color: $mm-grey-text;
      }
    }
  }

  .tab-content {
    .tab-pane {
      padding: 1em 0;
    }
  }

}
