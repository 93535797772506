@import '../../styles/colors';

.score-description-helper {
  position: relative;
  display: inline-block;
  color: $mm-grey-text;
  font-size: 0.8em;
  margin: 1em 0;

  svg {
    margin-left: 0.4em;
  }

  &.active {
    svg {
      color: $mm-blue;
    }
  }

  .popover {
    top: 100%;
    margin-top: 0;
    min-width: 180px;
  }
}