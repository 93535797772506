@import './_colors';

$primary-color: $mm-blue;
@import '../../node_modules/loaders.css/src/animations/ball-grid-beat.scss';

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}
