@import '../../styles/_colors';

.score-arc-svg {
  .score-arc {
    circle, line {
      transition: all 0.3s;
    }
  }
  .score-text {
    &.threshold-not-met text {
      fill: $mm-grey-text;
    }
  }
}