.org-insight-page {
  .intro-right {
    display: none;
  }

  .org-insights .table-responsive {
    overflow-x: -moz-scrollbars-horizontal;
    overflow-x: scroll;
    min-height: 400px;
  }
}
