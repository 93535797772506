.not-found-page {
  padding: 80px 0 40px;

  .not-found-nav {
    margin-top: 120px;
  }

  .nav-link {
    > svg {
      margin-right: 0.5em;
    }
  }
}