@import '../../styles/colors';

.construct-card {
  border: 1px solid $mm-stone;
  padding: 1em;
  border-radius: 4px;
  box-shadow: 1px 1px 2px $mm-stone;

  .construct-title {
    font-size: 1.2em;
    line-height: 1.3em;

    svg {
      display: block;
      float: left;
      height: 25px;
      width: 25px;
      margin-right: 0.45em;
    }
  }

  .construct-content {
    display: flex;
    padding: 1em 1em 0 0;
  }

  .construct-description {
    flex: 1;
    color: $mm-grey-text;
    padding-right: 2em;

    p {
      min-height: 55px;
    }
  }

  .construct-result {
    flex: 0 0 150px;
    text-align: center;

    .score-arc-svg {
      width: 150px;
      height: 75px;
    }

    > span {
      font-size: 0.65em;
      color: $mm-grey-text;
      text-transform: uppercase;
    }
  }
}