// vibrant colors
$mm-turquoise: #2fb6bc;
$mm-pink: #ff278d;
$mm-lime-green: #afc236;
$mm-blue: #2b86c7;
$mm-yellow: #ffd400;
$mm-orange: #e95a1a;

$dark-grayish-cerulean: #2e3f44;

// dark vibrant colors
$mm-dark-turquoise: #009695;
$mm-purple: #8e3f91;
$mm-green: #41ac4c;
$mm-dark-blue: #216b99;
$mm-gold: #f8ac04;
$mm-red: #e42925;

// neutral colors
$mm-white: #ffffff;
$mm-black: #000000;
$mm-stone: #edebe6;
$mm-light-stone: #f6f5f2;
$mm-dark-stone: #c9c2bf;
$mm-grey-text: #575756;

$payne-grey: #536e77;

$defaultColors: #53b585, #a9b687, #7dbce6, #df7f82, #9f83ba, #7b89ba, #c69979,
               #8fc1c3, $mm-turquoise, $mm-lime-green, $mm-blue, $mm-purple,
               $mm-dark-blue, $mm-yellow, $mm-dark-turquoise, $mm-green, $mm-gold;
$chartColors: $defaultColors;
$chartColorsCount: length($chartColors);

:export {
  mmTurquoise: $mm-turquoise;
  mmPink: $mm-pink;
  mmLimeGreen: $mm-lime-green;
  mmBlue: $mm-blue;
  mmYellow: $mm-yellow;
  mmOrange: $mm-orange;
  mmDarkTurquoise: $mm-dark-turquoise;
  mmPurple: $mm-purple;
  mmGreen: $mm-green;
  mmDarkBlue: $mm-dark-blue;
  mmGold: $mm-gold;
  mmRed: $mm-red;
  mmWhite: $mm-white;
  mmBlack: $mm-black;
  mmStone: $mm-stone;
  mmLightStone: $mm-light-stone;
  mmDarkStone: $mm-dark-stone;
  mmGreyText: $mm-grey-text;
  chartColors: $chartColors;
  defaultColors: $defaultColors;
  payneGrey: $payne-grey;
}
