.score-box {
  color: #fff;
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  display: flex;
  align-items: center;

  .score {
    margin: 0;
    flex: 1 1 100%;
  }

  span.suffix {
    font-size: 0.5em;
  }
}