@import '../../styles/_colors';

.report-page {
  padding: 2em;
  color: $mm-grey-text;

  .page-head {
    border-bottom: 1px solid $mm-stone;
    padding-bottom: 1em;

    h1 {
      font-size: 2.4em;
      margin: 0;
      color: $mm-grey-text;
    }

    .sub-title {
      color: $mm-dark-stone;
    }
  }

  .page-icon {
    height: 0.8em;
    font-size: 0.8em;
    width: 0.8em;
    float: right;
    color: $mm-dark-stone;

    > svg {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .page-intro {
    padding: 1em 0;
    display: flex;
    flex-wrap: nowrap;
  }

  .intro-left {
    flex: 1;
    padding-right: 2em;
    color: $mm-grey-text;
  }

  .intro-right {
    flex: 0 0 33%;
  }

  .page-section {
    border-top: 1px solid $mm-stone;
    padding: 2.5em 0;

    .page-section-intro {
      font-size: 0.9em;
      color: lighten($mm-grey-text, 15%);
    }
  }

  .section-title {
    font-size: 1.0em;
    color: $mm-grey-text;
    text-transform: uppercase;
    margin-bottom: 2em;

    > svg {
      float: right;
      opacity: 0.5;
    }
  }
}
