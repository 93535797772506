@import '../../styles/colors';

.construct-details-page {
  .construct-score-container {
    .score-arc-svg {
      margin: 0.2em 0;
      width: 180px;
      height: 90px;
    }
  }

  .page-intro {
    padding-right: 1em;

    .intro-right {
      flex: 0 0 180px;
    }
  }

  .construct-item-details {
    .section-title {
      margin-bottom: 1em;
    }

    .score-helper {
      text-align: right;
    }
  }

  .survey-item-details {
    margin-bottom: 1.5em;
  }
}
