@import '../../styles/colors';

.report-top-nav {
  background: $mm-turquoise;
  color: $mm-white;
  font-size: 0.9em;

  a {
    color: $mm-white;

    &:hover {
      color: $mm-light-stone;
    }
  }

  .nav-items {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .administration,
    .return-home {
      line-height: 3.5em;
      flex: 0 0 100px;
    }

    .return-home {
      .arrow-icon {
        margin-right: 0.5em;
      }
    }

    .report-scope-selector {
      flex: 1;
      text-align: center;

      .dropdown {
        button {
          border: 0;
          background: none;
          box-shadow: none;
        }
      }
    }

    .administration {
      justify-self: end;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}