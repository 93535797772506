@import '../../styles/_colors';

.likert-item-response-distribution {
  display: flex;
  flex-wrap: nowrap;
  font-size: 0.8em;
  text-align: center;

  .distributions {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;

    .item-response-anchor {
      flex: 1 1 auto;
      min-width: 14px;
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }

      .response-count {
        font-weight: 600;
        padding: 0 2px;
        background: $mm-dark-stone;
        color: $mm-white;
        border-bottom: 3px solid white;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.scorable {
        .response-count {
          background: $mm-turquoise;
        }
      }

      &.favorable {
        .response-count {
          border-bottom: 3px solid $mm-yellow;
        }
      }
    }

    .total-label,
    .response-anchor {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $mm-grey-text;
    }
  }

  .total-count {
    flex: 0 0 64px;

    .count {
      border-bottom: 3px solid white;
    }
  }
}

.item-response-anchor-tooltip {
  p { margin: 0 }
}
