@import '../../styles/colors';

.survey-item-details {
  margin-bottom: 2em;

  .details-container {
    transition: all 0.15s;
    padding: 0 1em;
    border: 1px solid $mm-light-stone;
    border-radius: 0 0 3px 3px;
    border-top: 0;
  }

  .expand-details-container {
    text-align: right;
    border: 1px solid $mm-light-stone;
    border-top: 0;

    &.expanded {
      border-bottom: 0;
    }
  }

  .donut-chart {
    height: 100%;

    > svg {
      min-width: 280px;
      min-height: 280px;
    }
  }

  .no-details-message {
    text-align: center;
    font-size: 0.9em;
    color: $mm-dark-stone;
    padding: 4px 0;
  }
}