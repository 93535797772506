@import '../../styles/_colors';

.score-bar {
  width: 100%;
  position: relative;
  text-align: center;
  background-color: $mm-stone;
  font-size: 0.8em;
  font-weight: 600;
  text-align: center;
  height: 2em;
  line-height: 2em;
  color: $mm-dark-stone;

  .score-bar-fill {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: visible;
  }

  .score-bar-text {
    padding: 0 0.5em;
    margin: 0;
    display: inline;
    white-space: nowrap;
    line-height: 2em;
    color: $mm-white;
  }
}
