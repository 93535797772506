@import '../../styles/colors';

.report-cover {
  background: $dark-grayish-cerulean;
}

.report-cover-content {
  padding: 80px 0;
  color: $mm-light-stone;
  position: relative;

  &:after {
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    left: 80px;
    bottom: -24px;
    border: 12px solid transparent;
    border-top-color: $dark-grayish-cerulean;
  }

  h1, h2, h5, h6 {
    color: $mm-white;
    line-height: 1.1em;
    margin: 0;
  }

  span {
    font-size: 0.8em;
    line-height: 1em;
  }

  display: flex;
  flex-wrap: nowrap;

  .report-subject {
    flex: 1;
    padding-right: 4em;

    .report-name {
      margin-bottom: 2em;

      h2 {
        margin: 0;
        text-transform: uppercase;
      }
    }
  }

  .report-scope {
    padding: 0;
    list-style: none;
    padding-top: 2em;

    li {
      display: inline-block;
      width: 33%;
      vertical-align: top;
    }
  }

  .responses {
    flex: 0 0 200px;

    .response-count {
      width: 160px;
      margin-bottom: 1em;
      text-align: center;

      svg {
        display: inline-block;
      }
    }

    .additional-info-box {
      display: inline-block;
      background: $mm-light-stone;
      width: 160px;
      border-radius: 6px;
      padding: 0.5em 0;
    }

    p {
      color: $mm-grey-text;
      text-align: center;

      &.total-responses,
      &.response-rate {
        font-size: 0.75em;
        line-height: 1.4em;
        margin: 0;

        span {
          color: $mm-dark-turquoise;
          font-size: 1em;
        }
      }
    }
  }
}
