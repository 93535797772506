@import '../../styles/_colors';

.demographics-page {
  .total-respondents-display {
    padding: 1em;
    text-align: center;

    .number-of-respondents {
      font-size: 3em;
      line-height: 1em;
    }

    .total-respondents-label {
      color: $mm-dark-stone;
      font-size: 0.9em;
    }
  }
}
